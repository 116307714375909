<template>
  <validation-observer
    v-slot="{ handleSubmit }"
  >
    <b-card header="Add Donor">
      <b-form @submit.prevent="handleSubmit(addDonor)">
        <b-form-row>
          <b-col md="6">
            <validation-provider
              #default="validationContext"
              name="First Name"
              rules="required|min:3|max:100|alpha_spaces"
            >
              <b-form-group
                label="First Name"
                label-for="first_name"
              >
                <b-form-input
                  id="first_name"
                  v-model="donor.first_name"
                  placeholder="First Name"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col md="6">
            <validation-provider
              #default="validationContext"
              name="Last Name"
              rules="required|min:3|max:100|alpha_spaces"
            >
              <b-form-group
                label="Last Name"
                label-for="last_name"
              >
                <b-form-input
                  id="last_name"
                  v-model="donor.last_name"
                  placeholder="Name"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col md="6">
            <validation-provider
              #default="validationContext"
              name="Phone"
              rules="required|min:8|max:14"
            >
              <b-form-group
                label="Phone"
                label-for="donor_phone"
              >
                <b-form-input
                  id="donor_phone"
                  v-model="donor.phone_number"
                  placeholder="Phone"
                  :state="getValidationState(validationContext)"
                  type="number"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="6">
            <validation-provider
              #default="validationContext"
              name="Email"
              rules="required|email"
            >
              <b-form-group
                label="Email"
                label-for="donor_email"
              >
                <b-form-input
                  id="donor_email"
                  v-model="donor.email"
                  placeholder="Email"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-form-row>
        <location-inputs
          :details="donor"
          :input-columns="4"
        />
        <b-button
          variant="primary"
          type="submit"
        >
          Add
        </b-button>
        <back />
      </b-form>
    </b-card>
  </validation-observer>
</template>
<script>
import formValidation from '@core/comp-functions/forms/form-validation'
import LocationInputs from '@/common/components/common/LocationInputs.vue'
import handleAlerts from '@/common/compositions/common/handleAlerts'
import Back from '@/common/components/common/Back.vue'

export default {
  name: 'AddDonor',
  components: {
    LocationInputs,
    Back,
  },
  data() {
    return {
      donor: {},
      donation: {},
    }
  },
  setup() {
    const { successfulOperationAlert, showErrors } = handleAlerts()
    const {
      getValidationState,
    } = formValidation()

    return {
      getValidationState,
      successfulOperationAlert,
      showErrors,
    }
  },
  methods: {
    addDonor() {
      this.$portalUsers.post('internalops/addDonor', this.donor).then(res => {
        this.successfulOperationAlert('Donor is added successfully')
        this.$store.commit('donor/setUser', this.donor.email)
        this.$router.push({ name: 'add-donation', params: { user_id: res.data.data.id } })
      }).catch(({ response }) => {
        this.showErrors(response.data.errors)
      })
    },
  },
}
</script>
<style lang="scss">
</style>
